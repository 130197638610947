@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

* {
  user-select: none;
}
body,
html {
  background-color: #000000;
}

.wallet-button {
  width: 12rem !important;
  border-radius: 1.5rem !important;
  background-color: #25ac88 !important;
  padding: 0.5rem 0 !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  color: #000000 !important;
  min-width: fit-content;
  text-align: center;
}




.css-qvr9d9-CurrentUserBadge {
  border-radius: 1.5rem !important;
  background-color: #25ac88 !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  min-width: fit-content;
  text-align: center;
}

@keyframes gradient-diagonal {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient-diagonal 5s ease-in-out infinite;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3c3b3b;
  border-radius: 16px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #3c3b3b;
}
.custom-scrollbar {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent #3c3b3b;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3c3b3b;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}
